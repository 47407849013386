import * as React from "react"
import { Box, Container, AppBar, Toolbar, Typography, DialogContent } from '@mui/material'
import ContactForm from '../components/ContactForm'

export const Head = () => (
  <>
    <title>HiMON® Angebot anfordern – HIGHVOLT</title>
    <meta name="viewport" content="initial-scale=1, width=device-width" />
    <meta name="robots" content="noindex,nofollow" />
  </>
)

const CalculationPage = () => {
  return (
    <Box className='hv-style'>
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography variant='h4' component='div' py={2} sx={{flexGrow: 1}}>Fordern Sie Ihr Angebot an</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
          <ContactForm lang={'de'} />
        </DialogContent>
      </Container>
    </Box>
  )
}

export default CalculationPage
